<!--
* 创建人：罗兴
* 日 期：
* 描 述：
-->
<!--view 模板-->
<template>
  <div class="container">
    <div class="libs">
      <div class="top-bar">
        <span class="top-title">应用管理</span>
        <el-button
          type="primary"
          icon="el-icon-plus"
          plain
          round
          @click="toCreate"
          >创建应用</el-button
        >
      </div>
      <el-scrollbar style="height: 600px" class="myscrollbar">
        <div class="libs-box">
          <div class="libs-list">
            <div class="lib-item" v-for="item in menulist" :key="item.id">
              <div class="app-item">
                <el-image
                  class="app-icon"
                  :src="baseurl + item.iconUrl"
                  fit="fill"
                ></el-image>
                <div class="app-name">
                  {{ item.appName }}
                </div>
              </div>
              <div
                :class="
                  item.sfsj == 1 ? 'app-status app-status-03' : 'app-status'
                "
              >
                {{ item.sfsj == 1 ? '已上架' : '未上架' }}
              </div>
              <!---->
              <div class="app-btns">
                <i class="el-icon-edit" @click="toFormDetail(item.id)"></i>
                <i class="el-icon-delete ml20"></i>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import { getMenuList } from '../../api/mobile'
import { baseUrl } from '../../utils/http'
export default {
  data() {
    return {
      baseurl: baseUrl,
      menulist: [],
    }
  },
  created() {
    getMenuList().then((res) => {
      if (res.code === 200) {
        this.menulist = res.data
      }
    })
  },
  methods: {
    toCreate() {
      this.$router.push('/mobile/sy_mobile_menu_create/index')
    },
    toFormDetail(id) {
      this.$router.push({
        path: '/mobile/sy_mobile_menu_create/index',
        query: { id: id },
      })
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style scoped>
.container {
  background: rgb(255, 255, 255);
  min-height: 100%;
  padding: 62px;
}
.container .libs {
  max-width: 1200px;
  margin: 0px auto;
}
.container .libs .top-bar {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.container .libs .top-bar .top-title {
  font-size: 16px;
  font-weight: 700;
}
.el-button {
  padding: 9px 15px;
}
.libs-box {
  margin-top: 24px;
  background: rgb(244, 245, 246);
  padding-top: 14px;
  border-radius: 5px;
  box-shadow: rgb(211 211 211 / 60%) 0px 2px 5px 0px;
}
.lib-list {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 5px 5px;
  overflow: hidden;
}
.lib-item {
  padding: 0px 32px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  height: 80px;
  font-size: 14px;
  transition: background 0.2s ease-in 0s;
  background-color: #fff;
}
.lib-item:hover {
  background: rgb(237, 239, 241);
}
.app-item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 500px;
}
.app-icon {
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
}
.app-name {
  margin-left: 20px;
}
.app-status {
  color: rgb(121, 123, 134);
  width: 200px;
  text-align: right;
}
.app-status-03 {
  color: rgb(255, 185, 0);
}
.app-btns {
  color: rgb(51, 52, 58);
  font-size: 16px;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
  text-align: right;
}
.el-icon-edit,
.el-icon-delete {
  cursor: pointer;
}
.el-icon-edit:hover,
.el-icon-delete:hover {
  color: #409eff;
}
</style>
